import { gql } from "graphql-request";
import { useTranslations } from "next-intl";

import { TextBlock } from "@shared/components";
import { Text } from "@shared/elements";

import { MESSAGE_PREFIX } from "src/constants";

type Props = {
  contact: string;
};

export default function Contact({ contact }: Props) {
  const t = useTranslations(`${MESSAGE_PREFIX}components.Contact`);

  return (
    <div className="md:w-[700px] max-w-screen-md px-4 md:px-0 mx-auto">
      <Text as="h1" size="3xl" weight="bold" className="mb-4">
        {t("title")}
      </Text>
      <Text as="p" className="mb-4">
        {t("contactUs")}
      </Text>
      <TextBlock content={contact} />
    </div>
  );
}

Contact.messages = [`${MESSAGE_PREFIX}components.Contact`];

Contact.graphql = {
  fragments: gql`
    fragment contactFields on Call {
      contact
    }
  `,
};
