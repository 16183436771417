// THIS FILE IS AUTO-GENERATED, DO NOT EDIT!
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  IntlTextField: any;
  JSON: any;
};

export type TAsset = {
  __typename?: "Asset";
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  fileExtension: Scalars["String"];
  fileKey: Scalars["String"];
  files?: Maybe<TAssetFiles>;
  frameRate?: Maybe<Scalars["Float"]>;
  height?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  isImage: Scalars["Boolean"];
  isVideo: Scalars["Boolean"];
  location?: Maybe<Scalars["String"]>;
  originalFilename: Scalars["String"];
  processPending?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uploadPending: Scalars["Boolean"];
  /** This upload url is only returned once during creation and then it will be null. */
  uploadUrl?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["Int"]>;
};

export enum TAssetFieldsEnum {
  Country = "COUNTRY",
  Date = "DATE",
  Description = "DESCRIPTION",
  Location = "LOCATION",
  Title = "TITLE",
}

export type TAssetFiles = {
  __typename?: "AssetFiles";
  large?: Maybe<Scalars["String"]>;
  medium?: Maybe<Scalars["String"]>;
  mp4Urls?: Maybe<Array<Maybe<Scalars["String"]>>>;
  small?: Maybe<Scalars["String"]>;
  thumbnails?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type TAssetInput = {
  mimeType?: InputMaybe<Scalars["String"]>;
  /** The original filename of the asset including its extension */
  originalFilename: Scalars["String"];
};

export type TCall = {
  __typename?: "Call";
  abbreviation: Scalars["String"];
  additionalRequirementsInfo?: Maybe<Scalars["String"]>;
  /** Whether the user can edit a submission */
  allowsRevocation: Scalars["Boolean"];
  callInfo?: Maybe<TCallInfo>;
  callPages: Array<TCallPage>;
  categories: Array<TSubmissionCategory>;
  contact?: Maybe<Scalars["String"]>;
  contestListTeaser?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  currency: Scalars["String"];
  customFeeDescription?: Maybe<Scalars["String"]>;
  customFeeDescriptionShort?: Maybe<Scalars["String"]>;
  customNewsletterSignup1?: Maybe<Scalars["String"]>;
  customNewsletterSignup2?: Maybe<Scalars["String"]>;
  description: Scalars["IntlTextField"];
  fee: Scalars["Float"];
  forbidDuplicates: Scalars["Boolean"];
  /** List of profile fields that should be hidden from the user */
  hiddenProfileFields: Array<Maybe<TProfileFieldsEnum>>;
  id: Scalars["ID"];
  isFeeVoluntary?: Maybe<Scalars["Boolean"]>;
  logoDefault?: Maybe<Scalars["String"]>;
  maxProjects?: Maybe<Scalars["Int"]>;
  organisation?: Maybe<TOrganisation>;
  profileRequirements?: Maybe<Scalars["JSON"]>;
  requirements?: Maybe<Scalars["String"]>;
  slogan: Scalars["IntlTextField"];
  slug: Scalars["String"];
  submissionEndDate?: Maybe<Scalars["DateTime"]>;
  submissionIntroduction?: Maybe<Scalars["IntlTextField"]>;
  submissionStartDate?: Maybe<Scalars["DateTime"]>;
  termsAndConditions?: Maybe<Scalars["IntlTextField"]>;
  timeline?: Maybe<Scalars["JSON"]>;
  title?: Maybe<Scalars["IntlTextField"]>;
  transactionFee?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  useNewSubmissionWizard?: Maybe<Scalars["Boolean"]>;
  visibilityStatus: Scalars["String"];
  website?: Maybe<Scalars["String"]>;
};

export type TCallInfo = {
  __typename?: "CallInfo";
  call: TCall;
  costs?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  host?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  theme?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  whatToSubmit?: Maybe<Scalars["String"]>;
  whatToWin?: Maybe<Scalars["String"]>;
  whoCanSubmit?: Maybe<Scalars["String"]>;
};

export type TCallPage = {
  __typename?: "CallPage";
  about?: Maybe<Scalars["JSON"]>;
  brandColor?: Maybe<Scalars["String"]>;
  call: TCall;
  createdAt: Scalars["DateTime"];
  faq?: Maybe<Array<TCallPageFaq>>;
  hasFaq?: Maybe<Scalars["Boolean"]>;
  hasJury?: Maybe<Scalars["Boolean"]>;
  headerImage?: Maybe<Scalars["String"]>;
  headerImageCaption?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  jury?: Maybe<Array<TCallPageJury>>;
  jurySectionTitle?: Maybe<Scalars["String"]>;
  language: Scalars["String"];
  slug: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TCallPageFaq = {
  __typename?: "CallPageFaq";
  answer?: Maybe<Scalars["String"]>;
  question?: Maybe<Scalars["String"]>;
};

export type TCallPageJurors = {
  __typename?: "CallPageJurors";
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type TCallPageJury = {
  __typename?: "CallPageJury";
  jurors?: Maybe<Array<Maybe<TCallPageJurors>>>;
  title?: Maybe<Scalars["String"]>;
};

export enum TCallsFilterEntryStatus {
  Closed = "CLOSED",
  Open = "OPEN",
  OpenAndUpcoming = "OPEN_AND_UPCOMING",
  Upcoming = "UPCOMING",
}

export type TCallsFilterInput = {
  entryStatus?: InputMaybe<TCallsFilterEntryStatus>;
  visibilityStatus?: InputMaybe<TCallsFilterVisibilityStatus>;
};

export enum TCallsFilterVisibilityStatus {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type TDeleteConfirmation = {
  __typename?: "DeleteConfirmation";
  /** The id of the deleted resource */
  id: Scalars["ID"];
  message: Scalars["String"];
};

export type TFinishSubmissionInput = {
  customNewsletterSignup1?: InputMaybe<Scalars["Boolean"]>;
  customNewsletterSignup2?: InputMaybe<Scalars["Boolean"]>;
};

export enum TGenderType {
  Female = "FEMALE",
  Male = "MALE",
  Other = "OTHER",
}

export type TInvitation = {
  __typename?: "Invitation";
  accepted: Scalars["Boolean"];
  call?: Maybe<TCall>;
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  expiresAt: Scalars["DateTime"];
  id: Scalars["ID"];
  issuer?: Maybe<TUser>;
  label?: Maybe<TJurorLabel>;
  message?: Maybe<Scalars["String"]>;
  /** The URL to redirect to when the invitation is accepted */
  redirectTo: Scalars["String"];
  scope: Scalars["String"];
  token: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TInvoice = {
  __typename?: "Invoice";
  createdAt: Scalars["DateTime"];
  currency: Scalars["String"];
  id: Scalars["ID"];
  items: Array<TInvoiceItem>;
  /** List of payments for this Invoice. Returns all the Stripe Checkouts that are in `paid` status. */
  payments: Array<TStripeCheckout>;
  remainingAmount: Scalars["Float"];
  total: Scalars["Float"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
  voucher?: Maybe<TVoucher>;
};

export type TInvoiceItem = {
  __typename?: "InvoiceItem";
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  quantity: Scalars["Int"];
  title: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TJurorLabel = {
  __typename?: "JurorLabel";
  category?: Maybe<TSubmissionCategory>;
  id: Scalars["ID"];
};

export type TOrganisation = {
  __typename?: "Organisation";
  calls: Array<TCall>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["IntlTextField"]>;
  id: Scalars["ID"];
  name: Scalars["IntlTextField"];
  slug: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TProfile = {
  __typename?: "Profile";
  artistName?: Maybe<Scalars["String"]>;
  biography?: Maybe<Scalars["String"]>;
  countryOrigin?: Maybe<Scalars["String"]>;
  countryResidence?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["Date"]>;
  facebook?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  gender?: Maybe<TGenderType>;
  instagram?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  publicName?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  userId: Scalars["ID"];
  website?: Maybe<Scalars["String"]>;
};

export enum TProfileFieldsEnum {
  Address = "ADDRESS",
  ArtistName = "ARTIST_NAME",
  BioLong = "BIO_LONG",
  CompanyName = "COMPANY_NAME",
  CountryOrigin = "COUNTRY_ORIGIN",
  CountryResidence = "COUNTRY_RESIDENCE",
  Cv = "CV",
  DateOfBirth = "DATE_OF_BIRTH",
  Facebook = "FACEBOOK",
  Gender = "GENDER",
  Instagram = "INSTAGRAM",
  Phone = "PHONE",
  Profession = "PROFESSION",
  RepresentedByAgency = "REPRESENTED_BY_AGENCY",
  RepresentedByGallery = "REPRESENTED_BY_GALLERY",
  Twitter = "TWITTER",
  Website = "WEBSITE",
}

export type TProfileInput = {
  /** This field needs a better concept. It's likely to be deprecated in the future. */
  artistName?: InputMaybe<Scalars["String"]>;
  biography?: InputMaybe<Scalars["String"]>;
  countryOrigin?: InputMaybe<Scalars["String"]>;
  countryResidence?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  /** Facebook username */
  facebook?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  gender?: InputMaybe<TGenderType>;
  /** Instagram handle without leading @ */
  instagram?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  /** Twitter handle without leading @ */
  twitter?: InputMaybe<Scalars["String"]>;
  /** Url of the user website */
  website?: InputMaybe<Scalars["String"]>;
};

export type TProject = {
  __typename?: "Project";
  assets?: Maybe<Array<Maybe<TProjectAsset>>>;
  attributeOverrides?: Maybe<Scalars["JSON"]>;
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customSubmissionFields?: Maybe<Scalars["JSON"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  location: Scalars["String"];
  ownerId?: Maybe<Scalars["ID"]>;
  rootId?: Maybe<Scalars["ID"]>;
  submissionCategoryId?: Maybe<Scalars["ID"]>;
  submissionIndex?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  year: Scalars["String"];
};

export type TProjectAsset = {
  __typename?: "ProjectAsset";
  assetId: Scalars["ID"];
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customFieldsValues?: Maybe<Scalars["JSON"]>;
  date?: Maybe<TProjectAssetDate>;
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  fileExtension: Scalars["String"];
  fileKey: Scalars["String"];
  files?: Maybe<TAssetFiles>;
  frameRate?: Maybe<Scalars["Float"]>;
  height?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  isImage: Scalars["Boolean"];
  isVideo: Scalars["Boolean"];
  location?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  originalFilename: Scalars["String"];
  processPending?: Maybe<Scalars["Boolean"]>;
  submissionCategoryId: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uploadPending: Scalars["Boolean"];
  width?: Maybe<Scalars["Int"]>;
};

export type TProjectAssetDate = {
  __typename?: "ProjectAssetDate";
  day?: Maybe<Scalars["Int"]>;
  month?: Maybe<Scalars["Int"]>;
  year?: Maybe<Scalars["Int"]>;
};

export type TProjectAssetDateInput = {
  day?: InputMaybe<Scalars["Int"]>;
  month?: InputMaybe<Scalars["Int"]>;
  year?: InputMaybe<Scalars["Int"]>;
};

export type TProjectAssetInput = {
  /** The id of the asset that should be added to the project */
  assetId: Scalars["ID"];
  country?: InputMaybe<Scalars["String"]>;
  /** The creation date of the asset */
  date?: InputMaybe<TProjectAssetDateInput>;
  description?: InputMaybe<Scalars["String"]>;
  /** The id of the project-asset. If not provided, a new project-asset will be created. */
  id?: InputMaybe<Scalars["ID"]>;
  location?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export enum TProjectFieldsEnum {
  Country = "COUNTRY",
  Description = "DESCRIPTION",
  Location = "LOCATION",
  Title = "TITLE",
  Year = "YEAR",
}

export type TProjectInput = {
  /** The assets of the project, will be stored in the provided order */
  assets: Array<TProjectAssetInput>;
  /** The country of the project */
  country?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  /** The location of the project */
  location?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  /** The year of the project */
  year?: InputMaybe<Scalars["String"]>;
};

export type TRootMutationType = {
  __typename?: "RootMutationType";
  acceptInvitation?: Maybe<TInvitation>;
  applyVoucher?: Maybe<TInvoice>;
  createAsset?: Maybe<TAsset>;
  createSubmission?: Maybe<TSubmission>;
  deleteProject?: Maybe<TDeleteConfirmation>;
  finishSubmission?: Maybe<TSubmission>;
  updateNewsletterSubscription?: Maybe<TUser>;
  updateProfile?: Maybe<TProfile>;
  upsertProject?: Maybe<TProject>;
};

export type TRootMutationTypeAcceptInvitationArgs = {
  id: Scalars["ID"];
  token: Scalars["String"];
};

export type TRootMutationTypeApplyVoucherArgs = {
  code?: InputMaybe<Scalars["String"]>;
  invoiceId: Scalars["ID"];
};

export type TRootMutationTypeCreateAssetArgs = {
  input: TAssetInput;
};

export type TRootMutationTypeCreateSubmissionArgs = {
  callId: Scalars["ID"];
};

export type TRootMutationTypeDeleteProjectArgs = {
  id: Scalars["ID"];
};

export type TRootMutationTypeFinishSubmissionArgs = {
  id: Scalars["ID"];
  input?: InputMaybe<TFinishSubmissionInput>;
};

export type TRootMutationTypeUpdateNewsletterSubscriptionArgs = {
  active: Scalars["Boolean"];
  source?: InputMaybe<Scalars["String"]>;
};

export type TRootMutationTypeUpdateProfileArgs = {
  input: TProfileInput;
  submissionId: Scalars["ID"];
};

export type TRootMutationTypeUpsertProjectArgs = {
  categoryId: Scalars["ID"];
  id?: InputMaybe<Scalars["ID"]>;
  input: TProjectInput;
  submissionId: Scalars["ID"];
};

export type TRootQueryType = {
  __typename?: "RootQueryType";
  assets: Array<Maybe<TAsset>>;
  call?: Maybe<TCall>;
  callPage?: Maybe<TCallPage>;
  calls: Array<Maybe<TCall>>;
  invitation?: Maybe<TInvitation>;
  invoice?: Maybe<TInvoice>;
  myProfile?: Maybe<TProfile>;
  mySubmission?: Maybe<TSubmission>;
  mySubmissions: Array<TSubmission>;
  project?: Maybe<TProject>;
};

export type TRootQueryTypeAssetsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type TRootQueryTypeCallArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type TRootQueryTypeCallPageArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type TRootQueryTypeCallsArgs = {
  filter?: InputMaybe<TCallsFilterInput>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type TRootQueryTypeInvitationArgs = {
  id: Scalars["ID"];
  token: Scalars["String"];
};

export type TRootQueryTypeInvoiceArgs = {
  submissionId: Scalars["ID"];
};

export type TRootQueryTypeMyProfileArgs = {
  submissionId: Scalars["ID"];
};

export type TRootQueryTypeMySubmissionArgs = {
  callId: Scalars["ID"];
};

export type TRootQueryTypeMySubmissionsArgs = {
  callIds: Array<Scalars["ID"]>;
};

export type TRootQueryTypeProjectArgs = {
  id: Scalars["ID"];
};

/** Stripe Checkouts towards an Invoice. */
export type TStripeCheckout = {
  __typename?: "StripeCheckout";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TSubmission = {
  __typename?: "Submission";
  call: TCall;
  /** Stripe checkout url. Only set in response to finishSubmission() mutation (if payment is required). */
  checkoutUrl?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customNewsletterSignup1?: Maybe<Scalars["Boolean"]>;
  customNewsletterSignup2?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  invoice?: Maybe<TInvoice>;
  isCoordinated?: Maybe<Scalars["Boolean"]>;
  projects: Array<TProject>;
  status: TSubmissionStatus;
  submittedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TSubmissionCategory = {
  __typename?: "SubmissionCategory";
  allowedFileTypes?: Maybe<Scalars["JSON"]>;
  call: TCall;
  createdAt: Scalars["DateTime"];
  description: Scalars["IntlTextField"];
  /** Contains the hidden fields for assets in this category. */
  hiddenAssetFields: Array<Maybe<TAssetFieldsEnum>>;
  /** Contains the hidden fields for projects in this category. */
  hiddenFields: Array<Maybe<TProjectFieldsEnum>>;
  id: Scalars["ID"];
  imagePrice?: Maybe<Scalars["Float"]>;
  imagePriceGraduation?: Maybe<Scalars["JSON"]>;
  label: Scalars["IntlTextField"];
  maxImages: Scalars["Int"];
  maxProjects: Scalars["Int"];
  maxVideoDuration?: Maybe<Scalars["Int"]>;
  minGifLongEdge?: Maybe<Scalars["Int"]>;
  minImageLongEdge: Scalars["Int"];
  minImages: Scalars["Int"];
  minProjects: Scalars["Int"];
  minVideoDuration?: Maybe<Scalars["Int"]>;
  minVideoLongEdge?: Maybe<Scalars["Int"]>;
  order?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["Float"]>;
  priceGraduation?: Maybe<Scalars["JSON"]>;
  /** Contains the required fields for assets in this category. */
  requiredAssetFields?: Maybe<Scalars["JSON"]>;
  /** Contains the required fields for projects in this category. */
  requiredFields?: Maybe<Scalars["JSON"]>;
  singleImagesCategory?: Maybe<Scalars["Boolean"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export enum TSubmissionStatus {
  Draft = "DRAFT",
  InEdit = "IN_EDIT",
  PaymentInProgress = "PAYMENT_IN_PROGRESS",
  Submitted = "SUBMITTED",
}

export type TUser = {
  __typename?: "User";
  firstName?: Maybe<Scalars["String"]>;
  gender?: Maybe<TGenderType>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  newsletter?: Maybe<Scalars["Boolean"]>;
  publicName?: Maybe<Scalars["String"]>;
};

export type TVoucher = {
  __typename?: "Voucher";
  code: Scalars["String"];
  type: TVoucherCalculationModeType;
  value: Scalars["Float"];
};

export enum TVoucherCalculationModeType {
  Fixed = "FIXED",
  Percentage = "PERCENTAGE",
}

export type TContactFields = { __typename?: "Call"; contact?: string | null };

export type TNavigationFields = {
  __typename?: "Call";
  id: string;
  slug: string;
  title?: any | null;
  submissionStartDate?: any | null;
  submissionEndDate?: any | null;
  timeline?: any | null;
  useNewSubmissionWizard?: boolean | null;
  categories: Array<{ __typename?: "SubmissionCategory"; id: string }>;
};

export type TCategoriesCallPageVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TCategoriesCallPage = {
  __typename?: "RootQueryType";
  callPage?: {
    __typename?: "CallPage";
    headerImage?: string | null;
    slug: string;
    hasJury?: boolean | null;
    hasFaq?: boolean | null;
    jurySectionTitle?: string | null;
    headerImageCaption?: string | null;
    call: {
      __typename?: "Call";
      title?: any | null;
      currency: string;
      slogan: any;
      description: any;
      slug: string;
      useNewSubmissionWizard?: boolean | null;
      logoDefault?: string | null;
      submissionStartDate?: any | null;
      submissionEndDate?: any | null;
      customFeeDescriptionShort?: string | null;
      isFeeVoluntary?: boolean | null;
      fee: number;
      id: string;
      timeline?: any | null;
      contact?: string | null;
      website?: string | null;
      categories: Array<{
        __typename?: "SubmissionCategory";
        id: string;
        label: any;
        description: any;
        price?: number | null;
        priceGraduation?: any | null;
        imagePrice?: number | null;
        imagePriceGraduation?: any | null;
      }>;
      callInfo?: {
        __typename?: "CallInfo";
        id: string;
        costs?: string | null;
        host?: string | null;
        theme?: string | null;
        whatToSubmit?: string | null;
        whatToWin?: string | null;
        whoCanSubmit?: string | null;
      } | null;
    };
  } | null;
};

export type TFaqCallPageVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TFaqCallPage = {
  __typename?: "RootQueryType";
  callPage?: {
    __typename?: "CallPage";
    headerImage?: string | null;
    slug: string;
    hasJury?: boolean | null;
    hasFaq?: boolean | null;
    jurySectionTitle?: string | null;
    headerImageCaption?: string | null;
    faq?: Array<{
      __typename?: "CallPageFaq";
      question?: string | null;
      answer?: string | null;
    }> | null;
    call: {
      __typename?: "Call";
      title?: any | null;
      slogan: any;
      description: any;
      slug: string;
      useNewSubmissionWizard?: boolean | null;
      logoDefault?: string | null;
      submissionStartDate?: any | null;
      submissionEndDate?: any | null;
      customFeeDescriptionShort?: string | null;
      isFeeVoluntary?: boolean | null;
      fee: number;
      currency: string;
      id: string;
      timeline?: any | null;
      contact?: string | null;
      website?: string | null;
      categories: Array<{
        __typename?: "SubmissionCategory";
        price?: number | null;
        imagePrice?: number | null;
        id: string;
      }>;
      callInfo?: {
        __typename?: "CallInfo";
        id: string;
        costs?: string | null;
        host?: string | null;
        theme?: string | null;
        whatToSubmit?: string | null;
        whatToWin?: string | null;
        whoCanSubmit?: string | null;
      } | null;
    };
  } | null;
};

export type TIndexCallPageVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TIndexCallPage = {
  __typename?: "RootQueryType";
  callPage?: {
    __typename?: "CallPage";
    about?: any | null;
    headerImage?: string | null;
    slug: string;
    hasJury?: boolean | null;
    hasFaq?: boolean | null;
    jurySectionTitle?: string | null;
    headerImageCaption?: string | null;
    call: {
      __typename?: "Call";
      title?: any | null;
      slogan: any;
      description: any;
      slug: string;
      useNewSubmissionWizard?: boolean | null;
      logoDefault?: string | null;
      submissionStartDate?: any | null;
      submissionEndDate?: any | null;
      customFeeDescriptionShort?: string | null;
      isFeeVoluntary?: boolean | null;
      fee: number;
      currency: string;
      id: string;
      timeline?: any | null;
      contact?: string | null;
      website?: string | null;
      categories: Array<{
        __typename?: "SubmissionCategory";
        price?: number | null;
        imagePrice?: number | null;
        id: string;
      }>;
      callInfo?: {
        __typename?: "CallInfo";
        id: string;
        costs?: string | null;
        host?: string | null;
        theme?: string | null;
        whatToSubmit?: string | null;
        whatToWin?: string | null;
        whoCanSubmit?: string | null;
      } | null;
    };
  } | null;
};

export type TJuryCallPageVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TJuryCallPage = {
  __typename?: "RootQueryType";
  callPage?: {
    __typename?: "CallPage";
    jurySectionTitle?: string | null;
    headerImage?: string | null;
    slug: string;
    hasJury?: boolean | null;
    hasFaq?: boolean | null;
    headerImageCaption?: string | null;
    jury?: Array<{
      __typename?: "CallPageJury";
      title?: string | null;
      jurors?: Array<{
        __typename?: "CallPageJurors";
        name?: string | null;
        avatar?: string | null;
        jobTitle?: string | null;
        bio?: string | null;
      } | null> | null;
    }> | null;
    call: {
      __typename?: "Call";
      title?: any | null;
      slogan: any;
      description: any;
      slug: string;
      useNewSubmissionWizard?: boolean | null;
      logoDefault?: string | null;
      submissionStartDate?: any | null;
      submissionEndDate?: any | null;
      customFeeDescriptionShort?: string | null;
      isFeeVoluntary?: boolean | null;
      fee: number;
      currency: string;
      id: string;
      timeline?: any | null;
      contact?: string | null;
      website?: string | null;
      categories: Array<{
        __typename?: "SubmissionCategory";
        price?: number | null;
        imagePrice?: number | null;
        id: string;
      }>;
      callInfo?: {
        __typename?: "CallInfo";
        id: string;
        costs?: string | null;
        host?: string | null;
        theme?: string | null;
        whatToSubmit?: string | null;
        whatToWin?: string | null;
        whoCanSubmit?: string | null;
      } | null;
    };
  } | null;
};

export type TTermsCallPageVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TTermsCallPage = {
  __typename?: "RootQueryType";
  callPage?: {
    __typename?: "CallPage";
    headerImage?: string | null;
    slug: string;
    hasJury?: boolean | null;
    hasFaq?: boolean | null;
    jurySectionTitle?: string | null;
    headerImageCaption?: string | null;
    call: {
      __typename?: "Call";
      title?: any | null;
      termsAndConditions?: any | null;
      slogan: any;
      description: any;
      slug: string;
      useNewSubmissionWizard?: boolean | null;
      logoDefault?: string | null;
      submissionStartDate?: any | null;
      submissionEndDate?: any | null;
      customFeeDescriptionShort?: string | null;
      isFeeVoluntary?: boolean | null;
      fee: number;
      currency: string;
      id: string;
      timeline?: any | null;
      contact?: string | null;
      website?: string | null;
      categories: Array<{
        __typename?: "SubmissionCategory";
        price?: number | null;
        imagePrice?: number | null;
        id: string;
      }>;
      callInfo?: {
        __typename?: "CallInfo";
        id: string;
        costs?: string | null;
        host?: string | null;
        theme?: string | null;
        whatToSubmit?: string | null;
        whatToWin?: string | null;
        whoCanSubmit?: string | null;
      } | null;
    };
  } | null;
};

export type TTimelineCallPageVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TTimelineCallPage = {
  __typename?: "RootQueryType";
  callPage?: {
    __typename?: "CallPage";
    headerImage?: string | null;
    slug: string;
    hasJury?: boolean | null;
    hasFaq?: boolean | null;
    jurySectionTitle?: string | null;
    headerImageCaption?: string | null;
    call: {
      __typename?: "Call";
      title?: any | null;
      timeline?: any | null;
      slogan: any;
      description: any;
      slug: string;
      useNewSubmissionWizard?: boolean | null;
      logoDefault?: string | null;
      submissionStartDate?: any | null;
      submissionEndDate?: any | null;
      customFeeDescriptionShort?: string | null;
      isFeeVoluntary?: boolean | null;
      fee: number;
      currency: string;
      id: string;
      contact?: string | null;
      website?: string | null;
      categories: Array<{
        __typename?: "SubmissionCategory";
        price?: number | null;
        imagePrice?: number | null;
        id: string;
      }>;
      callInfo?: {
        __typename?: "CallInfo";
        id: string;
        costs?: string | null;
        host?: string | null;
        theme?: string | null;
        whatToSubmit?: string | null;
        whatToWin?: string | null;
        whoCanSubmit?: string | null;
      } | null;
    };
  } | null;
};

export type TAppFields = {
  __typename?: "CallPage";
  headerImage?: string | null;
  slug: string;
  hasJury?: boolean | null;
  hasFaq?: boolean | null;
  jurySectionTitle?: string | null;
  headerImageCaption?: string | null;
  call: {
    __typename?: "Call";
    slogan: any;
    description: any;
    slug: string;
    useNewSubmissionWizard?: boolean | null;
    title?: any | null;
    logoDefault?: string | null;
    submissionStartDate?: any | null;
    submissionEndDate?: any | null;
    customFeeDescriptionShort?: string | null;
    isFeeVoluntary?: boolean | null;
    fee: number;
    currency: string;
    id: string;
    timeline?: any | null;
    contact?: string | null;
    website?: string | null;
    categories: Array<{
      __typename?: "SubmissionCategory";
      price?: number | null;
      imagePrice?: number | null;
      id: string;
    }>;
    callInfo?: {
      __typename?: "CallInfo";
      id: string;
      costs?: string | null;
      host?: string | null;
      theme?: string | null;
      whatToSubmit?: string | null;
      whatToWin?: string | null;
      whoCanSubmit?: string | null;
    } | null;
  };
};

export type TAppMySubmissionVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type TAppMySubmission = {
  __typename?: "RootQueryType";
  mySubmission?: {
    __typename?: "Submission";
    id: string;
    status: TSubmissionStatus;
  } | null;
};

export type TError404CallPageVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TError404CallPage = {
  __typename?: "RootQueryType";
  callPage?: { __typename?: "CallPage"; slug: string } | null;
};

export type TError500CallPageVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TError500CallPage = {
  __typename?: "RootQueryType";
  callPage?: { __typename?: "CallPage"; slug: string } | null;
};

export type TCallPageHeroFields = {
  __typename?: "CallPage";
  headerImage?: string | null;
  headerImageCaption?: string | null;
  call: {
    __typename?: "Call";
    slug: string;
    useNewSubmissionWizard?: boolean | null;
    title?: any | null;
    logoDefault?: string | null;
    description: any;
    submissionStartDate?: any | null;
    submissionEndDate?: any | null;
    customFeeDescriptionShort?: string | null;
    isFeeVoluntary?: boolean | null;
    fee: number;
    currency: string;
    id: string;
    website?: string | null;
    categories: Array<{
      __typename?: "SubmissionCategory";
      price?: number | null;
      imagePrice?: number | null;
    }>;
    callInfo?: {
      __typename?: "CallInfo";
      id: string;
      costs?: string | null;
      host?: string | null;
      theme?: string | null;
      whatToSubmit?: string | null;
      whatToWin?: string | null;
      whoCanSubmit?: string | null;
    } | null;
  };
};

export type TQuickInfoFields = {
  __typename?: "Call";
  id: string;
  slug: string;
  submissionStartDate?: any | null;
  submissionEndDate?: any | null;
  title?: any | null;
  website?: string | null;
  useNewSubmissionWizard?: boolean | null;
  callInfo?: {
    __typename?: "CallInfo";
    id: string;
    costs?: string | null;
    host?: string | null;
    theme?: string | null;
    whatToSubmit?: string | null;
    whatToWin?: string | null;
    whoCanSubmit?: string | null;
  } | null;
};

export const QuickInfoFields = gql`
  fragment quickInfoFields on Call {
    id
    slug
    submissionStartDate
    submissionEndDate
    title
    website
    useNewSubmissionWizard
    callInfo {
      id
      costs
      host
      theme
      whatToSubmit
      whatToWin
      whoCanSubmit
    }
  }
`;
export const CallPageHeroFields = gql`
  fragment callPageHeroFields on CallPage {
    headerImage
    headerImageCaption
    call {
      ...quickInfoFields
      slug
      useNewSubmissionWizard
      title
      logoDefault
      description
      submissionStartDate
      submissionEndDate
      customFeeDescriptionShort
      isFeeVoluntary
      fee
      currency
      categories {
        price
        imagePrice
      }
    }
  }
  ${QuickInfoFields}
`;
export const NavigationFields = gql`
  fragment navigationFields on Call {
    id
    slug
    title
    submissionStartDate
    submissionEndDate
    timeline
    useNewSubmissionWizard
    categories {
      id
    }
  }
`;
export const ContactFields = gql`
  fragment contactFields on Call {
    contact
  }
`;
export const AppFields = gql`
  fragment appFields on CallPage {
    ...callPageHeroFields
    headerImage
    slug
    hasJury
    hasFaq
    jurySectionTitle
    call {
      slogan
      description
      ...navigationFields
      ...contactFields
    }
  }
  ${CallPageHeroFields}
  ${NavigationFields}
  ${ContactFields}
`;
export const CategoriesCallPage = gql`
  query categoriesCallPage($slug: String!) {
    callPage(slug: $slug) {
      ...appFields
      call {
        title
        currency
        categories {
          id
          label
          description
          price
          priceGraduation
          imagePrice
          imagePriceGraduation
        }
      }
    }
  }
  ${AppFields}
`;
export const FaqCallPage = gql`
  query faqCallPage($slug: String!) {
    callPage(slug: $slug) {
      ...appFields
      faq {
        question
        answer
      }
      call {
        title
      }
    }
  }
  ${AppFields}
`;
export const IndexCallPage = gql`
  query indexCallPage($slug: String!) {
    callPage(slug: $slug) {
      ...appFields
      about
      call {
        title
      }
    }
  }
  ${AppFields}
`;
export const JuryCallPage = gql`
  query juryCallPage($slug: String!) {
    callPage(slug: $slug) {
      ...appFields
      jurySectionTitle
      jury {
        title
        jurors {
          name
          avatar
          jobTitle
          bio
        }
      }
      call {
        title
      }
    }
  }
  ${AppFields}
`;
export const TermsCallPage = gql`
  query termsCallPage($slug: String!) {
    callPage(slug: $slug) {
      ...appFields
      call {
        title
        termsAndConditions
      }
    }
  }
  ${AppFields}
`;
export const TimelineCallPage = gql`
  query timelineCallPage($slug: String!) {
    callPage(slug: $slug) {
      ...appFields
      call {
        title
        timeline
      }
    }
  }
  ${AppFields}
`;
export const AppMySubmission = gql`
  query appMySubmission($callId: ID!) {
    mySubmission(callId: $callId) {
      id
      status
    }
  }
`;
export const Error404CallPage = gql`
  query error404CallPage($slug: String!) {
    callPage(slug: $slug) {
      slug
    }
  }
`;
export const Error500CallPage = gql`
  query error500CallPage($slug: String!) {
    callPage(slug: $slug) {
      slug
    }
  }
`;
