import classNames from "clsx";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { NextIntlClientProvider } from "next-intl";
import { useEffect, useRef } from "react";

import { useUser } from "@shared/auth";
import {
  CallPageHero,
  CookieBanner,
  PicterMenu,
  HostedByFooter,
  LegalLinks,
} from "@shared/components";
import { Text } from "@shared/elements";
import { useGraphSWR, useUpdateEffect } from "@shared/hooks";
import { IntercomProvider, MyGrowthBookProvider } from "@shared/providers";
import {
  areCookiesAccepted,
  acceptAllCookies,
  pageView,
  storeUtmParams,
} from "@shared/tracking";
import "../styles/globals.css";

import {
  AppMySubmission,
  TAppFields,
  TAppMySubmission,
} from "__generated__/types";

import Contact from "src/components/Contact";
import Navigation from "src/components/Navigation";

import bundleMessages from "src/utils/bundleMessages";

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    storeUtmParams(window.location.search.split("?")[1]);
    if (areCookiesAccepted()) {
      acceptAllCookies();
    }
  }, []);

  const contentSection = useRef<HTMLDivElement>(null);
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageView(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  useUpdateEffect(() => {
    if (!contentSection.current) return;
    window.scrollTo({
      // fyi: Below 56 is paddingTop in children container element in content section
      // If it is updated in future, it needs to be updated.
      top: contentSection.current.offsetTop - 56,
      left: 0,
      behavior: "smooth",
    });
  }, [router.pathname]);

  const user = useUser({
    returnAfterAuthentication: false,
  });

  const { data, messages } = pageProps as {
    data: { callPage?: TAppFields };
    messages?: ReturnType<typeof bundleMessages>;
  };

  const { data: submissionData } = useGraphSWR<TAppMySubmission>(
    AppMySubmission,
    {
      callId: data?.callPage?.call.id,
    },
  );

  const mySubmission = submissionData?.mySubmission;

  return (
    <>
      <Head>
        <meta property="og:type" content="website" key="og-type" />
        <meta property="og:site_name" content="Picter" key="og-site_name" />
        <meta
          property="og:description"
          key="og-description"
          content={
            data?.callPage?.call?.slogan?.en ??
            data?.callPage?.call?.description?.en
          }
        />
        <meta
          property="og:image"
          content={`https://${process.env.NEXT_PUBLIC_IMGIX_URL}${data?.callPage?.headerImage}?w=400`}
          key="og-image"
        />
      </Head>
      <NextIntlClientProvider
        messages={pageProps.messages}
        locale="en"
        // Get the current timezone from the browser
        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
      >
        <IntercomProvider
          appId={process.env["NEXT_PUBLIC_INTERCOM_APP_ID"] ?? ""}
          autoBoot
          initializeDelay={2000}
          autoBootProps={{ hideDefaultLauncher: true }}
        >
          <MyGrowthBookProvider>
            {data?.callPage && (
              <>
                {user.isAuthenticated && <PicterMenu user={user} />}
                <CallPageHero
                  callPage={data.callPage}
                  submissionStatus={mySubmission?.status}
                />
                <Navigation
                  call={data.callPage.call}
                  slug={data.callPage.slug}
                  hasJury={!!data.callPage.hasJury}
                  hasFaq={!!data.callPage.hasFaq}
                  jurySectionTitle={data.callPage.jurySectionTitle ?? ""}
                  submissionStatus={mySubmission?.status}
                />
              </>
            )}
            <div
              ref={contentSection}
              /* NOTE:
               * the following values used to calculate the min-height
               * represents the following:
               * 10px = the number to make it pixel perfect
               * 64px = fixed navbar height
               * 136px = footer height
               * space.12 = footer padding top
               * space.40 = footer padding botton
               * space.12 = footer padding botton lg
               */
              className={classNames(
                "min-h-[calc(100vh+10px-65px-136px-theme(space.12)-theme(space.40))]",
                "lg:min-h-[calc(100vh+10px-65px-136px-theme(space.12)-theme(space.12))]",
              )}
            >
              <Component {...pageProps} />
              {data?.callPage?.call.contact && (
                <Contact contact={data?.callPage.call.contact} />
              )}
            </div>
            {data?.callPage && (
              <HostedByFooter>
                <Text
                  as="p"
                  size="sm"
                  color="custom"
                  className="text-center mt-3 text-gray-400"
                >
                  <LegalLinks />
                </Text>
              </HostedByFooter>
            )}
            {/*
              For some reason, messages can be undefined sometimes. This seems to
              happen on revalidation only (when the json payload of a page is requested)
            */}
            {messages && <CookieBanner />}
          </MyGrowthBookProvider>
        </IntercomProvider>
      </NextIntlClientProvider>
    </>
  );
}

App.messages = [
  ...CallPageHero.messages,
  ...Contact.messages,
  ...CookieBanner.messages,
  ...HostedByFooter.messages,
  ...Navigation.messages,
  ...PicterMenu.messages,
  ...LegalLinks.messages,
];
