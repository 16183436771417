import classNames from "clsx";
import Link from "next/link";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";

import { Navbar, SubmissionTerms } from "@shared/components";
import { Button, Text } from "@shared/elements";
import { useSubmissionCTAButton, useSubmissionStatus } from "@shared/hooks";

import { TNavigationFields, TSubmissionStatus } from "__generated__/types";

import { MESSAGE_PREFIX } from "src/constants";



type NavigationProps = {
  slug: string;
  hasJury: boolean;
  hasFaq: boolean;
  jurySectionTitle?: string;
  call: TNavigationFields;
  submissionStatus?: TSubmissionStatus;
};
export default function Navigation({
  call,
  slug,
  hasJury,
  hasFaq,
  jurySectionTitle,
  submissionStatus,
}: NavigationProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}components.Navigation`);
  const status = useSubmissionStatus(
    call.submissionStartDate,
    call.submissionEndDate,
    submissionStatus,
  );
  const mainButton = useSubmissionCTAButton({
    status,
    callId: call.id,
    callSlug: call.slug,
    useNewSubmissionWizard: Boolean(call.useNewSubmissionWizard),
  });
  const [sticky, setSticky] = useState<boolean>(false);

  return (
    <>
      <Navbar.Container
        className="overflow-x-auto"
        sticky
        onStickyChange={setSticky}
      >
        <Navbar className="lg:overflow-y-hidden">
          <div className="flex space-x-4 pr-4">
            <Navbar.Item href={`/${slug}`} scroll={false}>
              {t("about")}
            </Navbar.Item>
            {call.categories?.length > 1 && (
              <Navbar.Item href={`/${slug}/categories`} scroll={false}>
                {t("categories")}
              </Navbar.Item>
            )}
            {hasJury && (
              <Navbar.Item href={`/${slug}/jury`} scroll={false}>
                {jurySectionTitle || t("jury")}
              </Navbar.Item>
            )}
            {call?.timeline?.length !== 0 && (
              <Navbar.Item href={`/${slug}/timeline`} scroll={false}>
                {t("timeline")}
              </Navbar.Item>
            )}
            {hasFaq && (
              <Navbar.Item href={`/${slug}/faq`} scroll={false}>
                {t("faq")}
              </Navbar.Item>
            )}
            <Navbar.Item href={`/${slug}/terms`} scroll={false}>
              {t("terms")}
            </Navbar.Item>
          </div>
          <div
            className={classNames(
              "hidden lg:flex pr-4 items-center space-x-4",
              "transition-transform duration-200 translate-y-full",
              sticky && mainButton && "translate-y-0",
            )}
          >
            {status === "OPEN" && (
              <Text
                as="p"
                size="xs"
                color="muted"
                className="w-[300px] text-right"
              >
                <SubmissionTerms />
              </Text>
            )}
            {mainButton && (
              <Button
                as={Link}
                variant="primary"
                className="shrink-0"
                href={mainButton.href}
              >
                {mainButton.label}
                <HiArrowNarrowRight className="ml-2" />
              </Button>
            )}
          </div>
        </Navbar>
      </Navbar.Container>
      <div
        className={classNames(
          "lg:hidden flex-col fixed bottom-0 right-0 left-0 items-center space-y-4 bg-white px-4 py-5 border-t border-gray-200",
          "transition-transform	duration-300 translate-y-full z-10",
          sticky && mainButton && "translate-y-0",
        )}
      >
        {mainButton && (
          <Button
            as={Link}
            variant="primary"
            className="w-full"
            href={mainButton.href}
          >
            {mainButton.label}
            <HiArrowNarrowRight className="ml-2" />
          </Button>
        )}
        {status === "OPEN" && (
          <Text as="p" size="xs" color="muted" className="">
            <SubmissionTerms />
          </Text>
        )}
      </div>
    </>
  );
}

Navigation.messages = [
  `${MESSAGE_PREFIX}components.Navigation`,
  ...useSubmissionCTAButton.messages,
  ...SubmissionTerms.messages,
];
